import React, { 
    useState, 
    useEffect 
} from 'react';
import { Form } from 'react-bootstrap';
import { DebounceInput } from 'react-debounce-input';
import axios from 'axios';

// componentes
import { 
    Card, 
    Col, 
    Row 
} from 'react-bootstrap';

import Carregador from '../../Componentes/Carregador';
import PaginacaoComponente from '../../Componentes/Paginacao';
import Registro from './Registro';

// componente
export default function Consultar(){

    // estados
    const [parametros, alterarParametros] = useState({
        pagina: 1,
        registrosPorPagina: 10,
        texto: ''
    });
    const [totalRegistros, alterarTotalRegistros] = useState(0);
    const [carregando, alterarCarregando] = useState(false);
    const [consulta, alterarConsulta] = useState([]);

    // consulta
    async function consultarRegistros(){

        // ativa carregamento
        alterarCarregando(true);

        try {

            // faz a requisição
            const { data } = await axios.get(`/relatorioGrupoCliente`, { params: parametros });

            // resgata registros
            alterarConsulta(data.registros);

            // resgata total de registros da consulta
            alterarTotalRegistros(parseInt(data.totalRegistros));

        } catch({ response }) {
            console.log(response)
        } finally {

            // desativa carregamento
            alterarCarregando(false);
        }
    }

    // inicializa
    useEffect(() => {
        consultarRegistros();
    }, [parametros]);

    return (
        <Row>
            <Col>
                <Card className="painel-consulta border-0 shadow-sm" >
                    <div className="card-header ">
                        <h4 className="card-title font-weight-bolder">
                            Relatório de clientes inadimplentes
                        </h4>
                        <div className="parametros">
                            <div className="parametros-inicial">
                                <DebounceInput 
                                    placeholder="Nome do cliente..." 
                                    debounceTimeout={400}
                                    element={Form.Control} 
                                    value={parametros.texto}
                                    onChange={(e) => {
                                        alterarParametros({
                                            ...parametros, 
                                            texto: e.target.value, 
                                            pagina: 1
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>                    
                    <Card.Body>                        
                        <table className="table table-hover tabela">
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Cliente</th>
                                    <th>Código de Parceiro</th>
                                    <th>Grupo Anterior</th>
                                    <th>Grupo Atual</th>
                                    <th>Data/Hora Alteração</th>
                                    <th>Fechamento REF. (Valor | Data)</th>
                                    <th>Fechamento Pós REF. (Valor | Data | Pago)</th>
                                    <th>Eventos Cancelados</th>
                                </tr>
                            </thead>
                            <tbody>
                                {consulta.map((registro, index) => 
                                    <Registro 
                                        key={index} 
                                        dados={registro.dados}
                                        dadosGrupo={registro.dadosGrupo}
                                        dadosCliente={registro.dadosCliente}
                                        dadosGrupoAnterior={registro.dadosGrupoAnterior}
                                        dadosSeguroAventuraFechamento={registro.dadosSeguroAventuraFechamento}
                                        dadosSeguroAventuraFechamentoPosterior={registro.dadosSeguroAventuraFechamentoPosterior}
                                    />
                                )}
                            </tbody>
                        </table>
                        <PaginacaoComponente
                            pagina={parametros.pagina}
                            registrosPorPagina={parametros.registrosPorPagina}
                            totalRegistros={totalRegistros}
                            alterouPagina={(pagina) => {
                                alterarParametros({...parametros, pagina: pagina});
                            }}
                        />
                        {carregando && 
                            <Carregador 
                                className="rounded-bottom" 
                            />
                        }
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}
