import React from 'react';
import moment from 'moment';

import { converterReal } from '../../../../Funcoes';

// elemento registro
export default function Registro({
    dados,
    dadosGrupo,
    dadosCliente,
    dadosGrupoAnterior,
    dadosSeguroAventuraFechamento,
    dadosSeguroAventuraFechamentoPosterior
}) {

    // prepara status pago
    let pago = '';

    // verifica se tem informações do fechamento posterior
    if(dadosSeguroAventuraFechamentoPosterior) {

        // resgata info do status pago
        const statusPago = dadosSeguroAventuraFechamentoPosterior.pago;

        // verifica se fechamento está pago
        pago = statusPago === "S" ? "Sim" : (statusPago === "P" ? "Pendente" : "Não");

    }

    // verifica valor e data dos dados da cobrança posterior
    const valorDataPosterior = dadosSeguroAventuraFechamentoPosterior 
        ? "R$ " + converterReal(dadosSeguroAventuraFechamentoPosterior.valorDiariaEditado) + " | " + moment(dadosSeguroAventuraFechamentoPosterior.data, 'YYYY-MM-DD').format('MM/YYYY') + " | " + pago
        : ""
    ;
    
    return <tr>
        <td className="estreito text-center font-weight-bold">{dados.id}</td>
        <td>{dadosCliente?.nome ?? ''}</td>
        <td>{dadosCliente?.cartaOferta ?? ''}</td>
        <td>{dadosGrupoAnterior ? dadosGrupoAnterior.descricao : ''}</td>
        <td>{dadosGrupo ? dadosGrupo.descricao: ''}</td>
        <td>{moment(dados.dataCadastro).format('DD/MM/YYYY [às] HH:mm')}</td>
        <td>R$ {converterReal(dadosSeguroAventuraFechamento.valorDiariaEditado) + " | " + moment(dadosSeguroAventuraFechamento.data, 'YYYY-MM-DD').format('MM/YYYY')}</td>
        <td>{valorDataPosterior}</td>
        <td>{dados.eventosCancelados === "S" ? "Sim" : "Não"}</td>
    </tr>
}