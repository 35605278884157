import React, { 
    useState, 
    useEffect 
} from 'react';
import axios from 'axios';
import moment from "moment";

// componentes
import { 
    Card, 
    Col, 
    Form,
    Row, 
    Button, 
    Container 
} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { EventosCartaOfertaComponent } from './components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Carregador from './../../../Painel/Componentes/Carregador';
import PaginacaoComponente from './../../../Painel/Componentes/Paginacao';
import Registro from './Registro';

import { intersectObject } from '../../../Funcoes';
import logoTipoAventura from './../../../../assets/rocaSeguroAventura.png';
import ModalParticipantes from './ModalParticipantes';
import Parametros from './Parametros';

import FechamentosCartaOferta from "./../FechamentosCartaOferta";
import ModalBoletos from './ModalBoletos';
import ModalDadosUsuario from './ModalDadosUsuario';

export default function EventosCartaOferta(){

    const dispatch = useDispatch();
    const dadosCartaOferta = useSelector(state => state.cartaOferta);
    
    // estados
    const history = useHistory();
    
    const [parametros, alterarParametros] = useState({
        pagina: 1,
        registrosPorPagina: 10,
        ativo: ['S'],
        texto: '',
        dataProgramadoInicio: null,
        dataProgramadoFim: null,
        estados: []
    });
    const [totalRegistros, alterarTotalRegistros] = useState(0);
    const [mostrarDadosUsuario, alterarMostrarDadosUsuario] = useState(false);
    const [carregando, alterarCarregando] = useState(false);
    const [carregandoAlteracaoPropriedade, alterarCarregandoAlteracaoPropriedade] = useState(false);
    const [possuiEventoGerarBoleto, alterarPossuiEventoGerarBoleto] = useState(false);
    const [mostrarModalBoletos, alterarMostrarModalBoletos] = useState(false);
    const [registros, alterarRegistros] = useState([]);
    const [erros, alterarErros] = useState({});
    const [buscando, alterarBuscando] = useState(false);
    const [gerandoApolice, alterarGerandoApolice] = useState(false);
    const [modalParticipantes, alterarModalParticipantes] = useState(null);
    const [mostrarTabela, alterarMostrarTabela] = useState(false);
    const [possuiGrupoPropriedade, alterarPossuiGrupoPropriedade] = useState(false);
    const [dadosCliente, alterarDadosCliente] = useState(null);
    const [meses, alterarMeses] = useState(null);  
    
    // função com o objetivo de atualizar o objeto que está no cachê com informações do banco
    function montaClienteInterseccao(cliente) {

        // prepara objeto para atualiza cachê
        const montaClienteObjeto = {
            tipoPessoa: cliente.tipoPessoa,
            nomeEvento: cliente.seguroEventoNome,
            localEvento: cliente.seguroEventoLocal,
            descricao: cliente.seguroEventoDescricao,
            numeroApolice: cliente.numeroApolice,
            cartaOferta: cliente.cartaOferta,
            bloquearEnvioEmailsSeguroAventura: cliente.bloquearEnvioEmailsSeguroAventura
        };

        // caso seja pessoa física
        if(montaClienteObjeto.tipoPessoa === 'F') {

            // atribui informações de pessoa física ao objeto
            montaClienteObjeto['responsavel'] = cliente.nome;
            montaClienteObjeto['cpf'] = cliente.cpf;
            montaClienteObjeto['email'] = cliente.email;
            montaClienteObjeto['telefone'] = cliente.telefoneComercial;

        } else {

            // atribui informações de pessoa jurídica ao objeto
            montaClienteObjeto['responsavel'] = cliente.responsavelJuridicoNome;
            montaClienteObjeto['cnpj'] = cliente.cnpj;
            montaClienteObjeto['razaoSocial'] = cliente.nome;
            montaClienteObjeto['email'] = cliente.responsavelJuridicoEmail;
            montaClienteObjeto['telefone'] = cliente.responsavelJuridicoTelefone;

        }
        
        // atualiza o cache
        dispatch({
            type: 'cartaOferta/ATUALIZAR',
            dados: intersectObject(dadosCartaOferta.dados, montaClienteObjeto)
        });

    }

    // função para consultar eventos da carta oferta
    async function consultarCartaOferta(){

        alterarCarregando(true);
        alterarErros({});

        try {
            
            // faz a requisição
            const { data } = await axios.get(`/seguroAventura/eventosCartaOferta`, {
                params: parametros
            });

            // prepara para verificar se algum evento está disponível para gerar boleto
            let possuiEventoParaGerarBoleto = false;
            
            // atualiza os estados
            alterarMeses(data.somatorioMeses);
            alterarDadosCliente(data.cliente);
            montaClienteInterseccao(data.cliente);
            alterarTotalRegistros(parseInt(data.totalRegistros))
            alterarRegistros(data.registros.map((registro) => {
                
                // verifica se possui evento disponível para gerar boleto
                if(
                    !possuiEventoParaGerarBoleto && 
                    registro.permiteGerarBoleto && 
                    !registro.linkBoleto && 
                    parseInt(registro.quantidadeParticipantes) > 0
                ) possuiEventoParaGerarBoleto = true; 

                return registro
            }));
            // se ja podia gerar boleto, deixa gerar mesmo com os filtros
            alterarPossuiEventoGerarBoleto(possuiEventoGerarBoleto ? possuiEventoGerarBoleto : (
                data.possuiEventoFinalizadoEmAberto === "S" ? true : possuiEventoParaGerarBoleto
            ));
            alterarPossuiGrupoPropriedade(data.possuiGrupoPropriedade);
            alterarMostrarTabela(true);

        } catch ({response}) {
            if(response && response.status && response.status === 400){
                if(response.data) {
                    alterarErros(response.data);
                    if(response.data.cartaOferta && response.data.cartaOferta === 'Não encontrado') {
                        dispatch({
                            type: 'cartaOferta/ATUALIZAR',
                            dados: null
                        });
                    }
                } 
            }
        } finally {
            alterarCarregando(false);
            alterarBuscando(false);
        }


    }    
    
    // função responsável por gerar o arquivo apólice do cliente
    async function gerarArquivoApolice() {

        // ativa carregamento da apólice
        alterarGerandoApolice(true);

        try {

            // realiza a requisiçaõ para download do arquivo
            const { data } = await axios.get(`/seguroAventura/pdfArquivoApolice`);

            // faz o download
            window.open(
                `${process.env.REACT_APP_URL_ARQUIVOS}/download.php?p=anexosEmail&f=${encodeURIComponent(data.temporario)}&n=${encodeURIComponent(data.nome)}`,
                '_blank'
            );

        } catch(e) {

            console.log(e);

        } finally {

            // desativa carregamento da apólice
            alterarGerandoApolice(false);

        }

    }
    
    // atualiza permissão dos eventos do tipo propriedade
    async function atualizarPermissaoEventoPropriedade(bloqueado) {

        // aplica o carregamento
        alterarCarregandoAlteracaoPropriedade(true);

        try {

            // atualiza status dos eventos
            const { data } = await axios.post(`/clientes/salvarComJwt`, {
                id: dadosCliente.id,
                seguroPropriedadeBloqueado: bloqueado
            });
            alterarDadosCliente(data.dados);

        } catch(e) {
            console.error(e);
        } finally {
            // desativa o carregamento
            alterarCarregandoAlteracaoPropriedade(false);
        }

    }   
    
    // inicia
    useEffect(() => {
        consultarCartaOferta();
    }, [parametros]);    
    
    return <EventosCartaOfertaComponent>
        <Container>
            <Row className="align-center justify-content-center">
                <div className="formulario">                  
                    <div className='container-bem-vindo'>
                        <div className='container-background-texto'>
                            <div className='container-titulo-icone'>
                                <p className='titulo-bem-vindo'>Bem vindo</p>
                                <button 
                                    className='botao-icone-usuario'
                                    onClick={() => alterarMostrarDadosUsuario(true)}
                                >
                                    <FontAwesomeIcon 
                                        className="icone" icon={["fa", "user"]} 
                                    />
                                </button>
                            </div>
                            <p className='nome-usuario'>{dadosCliente?.nome ?? ''}</p>
                        </div>
                    </div>     
                    <div className="logo">
                        <img src={logoTipoAventura} />
                    </div>
                    <div className='container-opcoes-topbar'>
                        <div className='botao-sinistro'>
                            <Button 
                                variant="padrao-azul" 
                                className="botao-cadastrar"
                                onClick={() => window.open('https://seguroaventura.com.br/comunicar-sinistro')}
                            >
                                <FontAwesomeIcon className="icone" icon={["fas", "exclamation-triangle"]} />
                                <span>Comunicar Sinistro</span>
                            </Button>
                        </div>
                        {(possuiGrupoPropriedade) &&
                            <>
                                {(!carregandoAlteracaoPropriedade) ? 
                                    <Form.Switch
                                        type="switch"
                                        label={dadosCliente.seguroPropriedadeBloqueado === "N" ? "Inscrição via QRCODE Habilitado" : "Inscrição via QRCODE Desabilitados"}                            
                                        color='white'
                                        checked={dadosCliente.seguroPropriedadeBloqueado === "N"}
                                        onChange={() => atualizarPermissaoEventoPropriedade(
                                            dadosCliente.seguroPropriedadeBloqueado === "S" ? "N" : "S"
                                        )}
                                    /> : 
                                    <FontAwesomeIcon 
                                        color='white'
                                        size={'2x'}
                                        icon={faSpinner} 
                                        pulse 
                                    />
                                }
                            </>
                        }
                    </div>
                </div>
            </Row>
            <Row className='p-3 bg-white rounded mt-3 mb-3 ml-0 mr-0' style={{ fontSize: '14px' }}>
                <Col className="p-0 flex-column justify-content-between d-flex col-6">
                    <div className='d-flex align-itens-center justify-content-center mb-2 font-weight-bold'>
                        <span 
                            className='bg-white p-2 rounded w-auto text-center border border-dark'
                            style={{ boxShadow: '0px 0px 0px 1px black' }}
                        >
                            COBERTURAS DO SEGURO
                        </span>
                    </div>
                    <Row className='flex-row d-flex align-center' style={{ gap: '5px' }}>
                        <Col className='d-flex justify-content-end align-center text-align-center flex-column'>
                            <text>Valor morte acidental</text>
                            <text className='d-flex w-100 p-1 mt-2 rounded border' style={{ background: '#e9ecef' }}>
                                R$ {dadosCliente ? dadosCliente.seguroValorMorteAcidental : '0.00'}
                            </text>
                        </Col>
                        <Col className='d-flex justify-content-end align-center flex-column'>
                            <text>Valor invalidez</text>
                            <text className='d-flex w-100 p-1 mt-2 rounded border' style={{ background: '#e9ecef' }}>
                                R$ {dadosCliente ? dadosCliente.seguroValorInvalidez : '0.00'}
                            </text>
                        </Col>
                        <Col className='d-flex justify-content-end align-center flex-column'>
                            <text>Valor DMHO</text>
                            <text className='d-flex w-100 p-1 mt-2 rounded border' style={{ background: '#e9ecef' }}>
                                R$ {dadosCliente ? dadosCliente.seguroValorDmho : '0.00'}
                            </text>
                        </Col>
                    </Row>
                </Col>
                <Col className="p-0 flex-column justify-content-between d-flex col-6" style={{ alignItems: 'flex-end' }}>                    
                    <div className='d-flex align-itens-center justify-content-end text-align-center mb-2 font-weight-bold' style={{ zIndex: 1 }}>
                        <span 
                            className='bg-white p-2 rounded w-auto text-center border border-dark' 
                            style={{ boxShadow: '0px 0px 0px 1px black' }}
                        >
                            VALOR POR PESSOA/DIA
                        </span>
                    </div>
                    <div className='d-flex justify-content-end text-end flex-column' style={{ alignItems: 'flex-end' }}>
                        <text className='text-end'>Valor</text>
                        <text className='d-flex w-auto p-1 mt-2 rounded border justify-content-end' style={{ background: '#e9ecef', minWidth: '180px' }}>
                            R$ {dadosCliente ? dadosCliente.seguroValorDiaria : '0.00'}
                        </text>
                    </div>                    
                </Col>
            </Row>            

            {
                mostrarTabela &&
                <>
                    <Card className="painel-consulta border-0 shadow-sm mb-3" >
                        <div className="card-header">
                            <Parametros 
                                parametros={parametros} 
                                permissaoCriarEvento={dadosCartaOferta?.dados?.clienteGrupoSuspenso === "N"}
                                gerandoApolice={gerandoApolice}
                                alterarParametros={alterarParametros}          
                                gerarApoliceCliente={gerarArquivoApolice}                       
                                possuiEventoGerarBoleto={possuiEventoGerarBoleto}
                                alterarMostrarModalBoletos={alterarMostrarModalBoletos}
                            />
                        </div>
                        
                        <Card.Body className="pt-0">
                            <table className="table table-hover tabela">
                                <thead>
                                    <tr>
                                        <th className="text-center"><FontAwesomeIcon icon={faUsers} /></th>
                                        <th>Evento</th>
                                        <th>Local</th>
                                        <th>Data início</th>
                                        <th>Data fim</th>
                                        {meses && 
                                            <>
                                                <th>{moment(meses[1]).format("MMMM")}</th>
                                                <th>{moment(meses[2]).format("MMMM")}</th>
                                            </>
                                        }
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {registros.map((registro, a) => 
                                        <Registro 
                                            key={registro.dados.id}
                                            dados={registro}
                                            clicouAbrir={() => {
                                                alterarModalParticipantes({
                                                    idSeguroAventura: registro.dados.id,
                                                    evento: registro.dados
                                                });
                                            }}
                                            cliente={dadosCliente}
                                            editarEvento={() => {
                                                history.push(`/formularios/propostaSeguro/evento/${registro.dados.token}`)
                                            }}
                                            consultarCartaOferta={consultarCartaOferta}
                                        />
                                    )}
                                </tbody>
                            </table>
                            <PaginacaoComponente
                                pagina={parametros.pagina}
                                registrosPorPagina={parametros.registrosPorPagina}
                                totalRegistros={totalRegistros}
                                alterouPagina={(pagina) => {
                                    alterarParametros({...parametros, pagina: pagina});
                                }}
                            />
                            {carregando && 
                                <Carregador className="rounded-bottom" />
                            }
                        </Card.Body>
                    </Card>

                    <FechamentosCartaOferta />
                </>
            }
        </Container>

        {mostrarDadosUsuario &&
            <ModalDadosUsuario 
                idCliente={dadosCliente.id}
                dadosCliente={dadosCliente}
                alterarDadosCliente={alterarDadosCliente}
                fechou={() => alterarMostrarDadosUsuario(false)}
            />
        }
    
        {modalParticipantes && (
            <ModalParticipantes 
                {...modalParticipantes}
                fechou={() => {
                    alterarModalParticipantes(null);
                }}
            />
        )}

        {mostrarModalBoletos && (
            <ModalBoletos 
                cliente={dadosCliente}
                consultarCartaOferta={consultarCartaOferta}
                mostrarModalBoletos={mostrarModalBoletos}
                alterarMostrarModalBoletos={alterarMostrarModalBoletos}  
            />
        )}

    </EventosCartaOfertaComponent>;

}
