import React, { 
    useEffect, 
    useState 
} from 'react';
import { 
    Container, 
    Card, 
    Form, 
    Col, 
    Row, 
    Alert, 
    Button, 
    Image, 
    Figure 
} from "react-bootstrap";
import { useParams } from 'react-router-dom';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MaskedInput from 'react-maskedinput';
import moment from 'moment';
import axios from 'axios';

import rocaPng from './../../../../assets/rocaSeguroAventura.png';
import InputData from './../../../Painel/Componentes/InputData';

// cadastrar cliente único ao evento
export default function CadastroClienteEvento() {

    // dados
    const { token } = useParams();
    const [dadosEvento, alterarDadosEvento] = useState(null);
    const [carregando, alterarCarregando] = useState(true);
    const [dados, alterarDados] = useState({
        numeroInscricao: "1",
        estrangeiro: "N",
        numeroPassaporte: null,
        cpf: null,
        aceitouTermos: "N",
        dataNascimento: null,
        contatoEmergenciaTelefone: null,
        contatoEmergenciaNome: null,
        nacionalidade: null,
        email: null,
        telefone: null,
        restricoesDadosSaude: null
    });
    const [erros, alterarErros] = useState({});    
    const [erro, alterarErro] = useState(null);
    const [salvando, alterarSalvando] = useState(false);
    const [finalizado, alterarFinalizado] = useState(false);
    const [consultandoCpf, alterarConsultandoCpf] = useState(false);
    const [termos, alterarTermos] = useState(false);
    const [aceitouTermos, alterarAceitouTermos] = useState(false);
    const [aceitouTermosCheckbox, alterarAceitouTermosCheckbox] = useState(false);
    const [nomeImagem, alterarNomeImagem] = useState(null);
    const [mostrarContatoEmergencia, alterarMostrarContatoEmergencia] = useState(false);
    const [mostrarDadosSaude, alterarMostrarDadosSaude] = useState(false);
    const [bloqueado, alterarBloqueado] = useState(false);
    const [jwt, alterarJwt] = useState(null);

    // referencias
    const telefoneInput = React.createRef();
    const telefoneEmergenciaInput = React.createRef();
    let cpfInput = React.createRef();
    
    // consultar token
    async function consultarToken() {

        // mostra carregamento
        alterarCarregando(true);

        try {

            // faz a requisição
            const { data } = await axios.get(`/seguroAventura/token/${token}`);

            alterarDados({
                ...dados,
                numeroInscricao: (data.participantes.length + 1).toString().padStart(3, '0')
            });
            alterarJwt(data.jwt);            
            alterarTermos({
                mostrar: data.exibirTermos === "S",
                linkTermoResponsabilidade: data.linkTermoResponsabilidade
            });
            alterarDadosEvento(data.dados);
            alterarNomeImagem(data.dadosImagemModeloEmailParticipante.nomeTemporario);

            // verifica status do evento
            if(data.permitidoAdicionarParticipante === "N" || data.dados.cancelado === "S") {
                alterarErro("Evento finalizado!");
            }

            // se o cadastro via link estiver bloqueado
            if(data.dados.linkInscricaoBloqueado === "S") {
                alterarBloqueado(true);
                alterarFinalizado(true);
            }

        } catch ({ response }) {
            if (response && response.status && response.status === 400) {
                alterarErro("Não foi possível carregar o evento!");
            }
        }
        finally {

            // esconde carregamento
            alterarCarregando(false);

        }
    }
    
    // salvar participante 
    async function salvar() {

        // bota pra carregar
        alterarSalvando(true);

        try {

            // faz a requisição pra incluir o participante
            await axios.put(`/seguroAventuraParticipante/evento/link/${dadosEvento.token}`, 
                {
                    dados: {
                        ...dados,
                        aceitouTermos: aceitouTermos ? "S" : "N"
                    }
                },
                {
                    headers: {
                        Authorization: jwt
                    }
                }
            );
            alterarFinalizado(true);

        } catch ({ response }) {

            // verifica response
            if (response && response.status && response.status === 400) {

                // se veio retorno de erro
                if (response.data) {

                    // verifica se é erro de bloqueio
                    if (Object.keys(response.data).includes('bloqueio')) {
                        
                        // altera erro do evento
                        alterarErro(response.data.bloqueio);

                    } else {

                        // altera erros do evento
                        alterarErros(response.data);                        

                    }

                }

            }

        } finally {
            alterarSalvando(false);
        }

    }
    
    // consulta cpf de participante já cadastrado
    async function consultarCpf() {
        
        alterarConsultandoCpf(true);

        try {

            // faz a requisição
            const { data } = await axios.get(`/seguroAventuraParticipante/cpf`, {
                params: {
                    cpf: dados.cpf
                },
                headers: {
                    Authorization: jwt
                }
            });

            // resgata dados cpf e preenche campos
            const dadosCpf = Object.assign(dados, {
                nome: data.dados.nome,
                dataNascimento: data.dados.dataNascimento,
                email: data.dados.email,
                telefone: data.dados.telefone,
                contatoEmergenciaTelefone: data.dados.contatoEmergenciaTelefone,
                contatoEmergenciaNome: data.dados.contatoEmergenciaNome,
                restricoesDadosSaude: data.dados.restricoesDadosSaude
            });

            // verifica para ativar checkbox
            alterarMostrarContatoEmergencia((dadosCpf.contatoEmergenciaNome || dadosCpf.contatoEmergenciaTelefone) ? true : false);
            alterarMostrarDadosSaude(dadosCpf?.restricoesDadosSaude ? true : false);

            // altera 
            alterarDados(dadosCpf);

        } catch ({ response }) { }

        finally {
            alterarConsultandoCpf(false);
        }

    }
    
    // inicializa
    useEffect(() => {
        consultarToken();
    }, []);

    useEffect(() => {
        // se tiver o erro
        if(erros?.contatoEmergenciaNome || erros?.contatoEmergenciaTelefone) {
            
            // habilita checkbox para mostrar o campo
            alterarMostrarContatoEmergencia(true);
        }

        // se tiver o erro
        if(erros?.restricoesDadosSaude) {
            
            // habilita checkbox para mostrar o campo
            alterarMostrarDadosSaude(true);
        }
    }, [erros])

    // sempre que o cpf for alterado
    useEffect(() => {

        // se preenchido faz a consulta do cpf
        if (dados.cpf !== null && dados.cpf.length === 11) {
            consultarCpf();
        }

    }, [dados.cpf]);
    
    // carregando os dados do token
    if (carregando) {
        return <div className="vh-100" style={{ backgroundColor: "#595959" }}>
            <style>
                {'body {background-color: #595959;}'}
            </style>
            <Container>
                <div className="text-center text-white pt-5">
                    Aguarde <FontAwesomeIcon className="ml-1" icon={faSpinner} pulse />
                </div>
            </Container>
        </div>
    }

    // se encontrado erro
    if (erro) {
        return <div className="vh-100" style={{ backgroundColor: "#595959" }}>
            <style>
                {'body {background-color: #595959;}'}
            </style>
            <Container>
                <div className="text-center text-white pt-5">
                    {erro}
                </div>
            </Container>
        </div>
    }

    // se encontrado erro
    if (finalizado) {
        return <div className="vh-100" style={{ backgroundColor: "#595959" }}>
            <style>
                {'body {background-color: #595959;}'}
            </style>
            <Container>
                <div className="text-center text-white pt-5 font-weight-bold">
                    {bloqueado ? 'Evento finalizado!' : 'Seus dados foram cadastrados com sucesso!'}
                </div>
                {!bloqueado && <div className="text-center text-white pt-2">
                    {dadosEvento.descricao}
                </div>}
                <div className="text-center text-white pt-2">
                    {(!bloqueado) && <Button
                        variant="padrao-azul"
                        className="botao-cadastrar shadow-none"
                        onClick={() => window.location.reload()}
                    >
                        <FontAwesomeIcon className="icone" icon={["fas", "plus"]} />
                        <span>Novo participante</span>
                    </Button>}
                </div>
                {(nomeImagem && !bloqueado) &&
                    <Form.Group style={{
                        display: 'flex',
                        textAlign: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flex: 1
                    }}>
                        <Figure style={{
                            maxWidth: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexGrow: 1,
                            marginBottom: 0
                        }}
                        >
                            <Image
                                src={`${process.env.REACT_APP_URL_ARQUIVOS}/arquivos/imagensEmail/${nomeImagem}`}
                                fluid
                                rounded
                                style={{
                                    maxHeight: '80vh',
                                    minHeight: 300,
                                    flexGrow: 1,
                                    marginTop: 10
                                }}
                                alt='Imagem modelo email do seguro aventura'
                                responsive
                            />
                        </Figure>
                    </Form.Group>
                }
            </Container>
        </div>
    }
    
    // se tem de mostrar termos
    if(termos.mostrar && !aceitouTermos) {
        return <div className="vh-100 vw-100" style={{ backgroundColor: "#595959" }}>
            <style>
                {'body {background-color: #595959;}'}
            </style>
            <div
                className='d-flex w-100 h-100 align-items-center justify-content-center flex-column'
            >
                <iframe 
                    id='iframe-pdf'
                    src={`${axios.defaults.baseURL}/clientes/baixarArquivoTermo?linkTermoResponsabilidade=${termos.linkTermoResponsabilidade}&id=${dadosEvento.idCliente}`}
                    style={{
                        flex: 1,
                        minWidth: '50vw',
                        maxWidth: '80vw',
                        maxHeight: '75vh',                        
                        border: '1px solid black'                        
                    }}
                />
                <div 
                    className='d-flex align-items-center justify-content-center w-100' 
                    style={{
                        height: 100, 
                        flexDirection: window.innerWidth < 500 ? 'column' : 'row'
                    }}
                >
                    <Form.Check
                        inline
                        label="ESTOU CIENTE E ACEITO OS TERMOS"
                        id={`checkbox-participante-termos`}
                        type='checkbox'                    
                        color='white'                    
                        onChange={e => alterarAceitouTermosCheckbox(!aceitouTermosCheckbox)}
                        checked={aceitouTermosCheckbox}
                        style={{ color: 'white' }}
                    />
                    {(aceitouTermosCheckbox) && <Button
                        className={["botao-cadastrar shadow-none",  window.innerWidth < 500 ? "mt-1" : "ml-1"].join(' ')}                         
                        onClick={() => alterarAceitouTermos(true)}
                    > 
                        <span>Avançar</span>                        
                    </Button>}
                </div>
            </div>
        </div>
    }

    // componente
    return <div className="vh-100" style={{ backgroundColor: "#595959" }}>
        <style>
            {'body {background-color: #595959;}'}
        </style>
        <Container>
            <Row>
                <Col 
                    lg={{ 
                        span: 6, 
                        offset: 3 
                    }} 
                    md={{ 
                        span: 8, 
                        offset: 2 
                    }} 
                    sm={{ 
                        span: 10, 
                        offset: 1 
                    }}
                >
                    <Card className="mt-4">
                        <Card.Body>

                            <Form.Group style={{
                                display: 'flex',
                                textAlign: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flex: 1
                            }}>
                                <Figure style={{
                                    maxWidth: '100%',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexGrow: 1,
                                    marginBottom: 0
                                }}>
                                    <Image
                                        src={rocaPng}
                                        fluid
                                        rounded
                                        style={{
                                            maxHeight: 150,
                                            flexGrow: 1
                                        }}
                                    />
                                </Figure>
                            </Form.Group>

                            <>
                                {(erros.dataNascimento || (dados.dataNascimento && dados.dataNascimento.length === 10 && moment().diff(dados.dataNascimento, 'years') <= 14)) &&
                                    <Alert variant="danger" className="aviso-data p-1 border-0">
                                        Para os menores de 14 anos a cobertura de Morte Acidental estará limitada ao reembolso de despesas com o funeral.
                                    </Alert>
                                }
                            </>

                            <Form.Group>
                                <Form.Group>
                                    <Form.Label>Evento: {dadosEvento?.nomeEvento || ''}</Form.Label>
                                </Form.Group>
                            </Form.Group>

                            <Form.Group>
                                <Form.Group style={{ justifyContent: 'space-between', flex: 1, display: 'flex' }}>
                                    <Form.Label>Data Início: {moment(dadosEvento.dataInicio).format('DD/MM/YYYY') || ''}</Form.Label>
                                    <Form.Label>Data Final: {moment(dadosEvento.dataFinal).format('DD/MM/YYYY') || ''}</Form.Label>
                                </Form.Group>
                            </Form.Group>

                            <Form.Group>
                                <Form.Group>
                                    <Form.Label>Local: {dadosEvento.localEvento || ''}</Form.Label>
                                </Form.Group>
                            </Form.Group>

                            <Form.Group>
                                <Form.Group>
                                    <Form.Label>Responsável: {dadosEvento.responsavel || ''}</Form.Label>
                                </Form.Group>
                            </Form.Group>

                            <hr />

                            <Form.Group>
                                <Form.Label className="d-flex align-items-center mt-1">
                                    <span className=" flex-grow-1">
                                        {dados.estrangeiro === "N" ? "CPF*" : "Número Passaporte*"}

                                    </span>
                                    <Form.Check
                                        label="É estrangeiro?"
                                        id="checkbox-participante-estrangeiro"
                                        onChange={e => {
                                            dados.estrangeiro = e.target.checked ? "S" : "N";
                                            alterarDados({ ...dados });
                                        }}
                                        checked={dados.estrangeiro === "S"}
                                    />
                                </Form.Label>


                                {dados.estrangeiro === "N" &&
                                    <>
                                        <MaskedInput
                                            className={[
                                                "form-control form-control-sm",
                                                (erros.cpf) && 'is-invalid'
                                            ].join(' ')}
                                            mask="111.111.111-11"
                                            value={dados.cpf}
                                            ref={cpfInput}
                                            onChange={(e) => {
                                                dados.cpf = cpfInput.current.mask.getRawValue().split('_').join('');
                                                alterarDados({ ...dados });
                                            }}
                                            style={consultandoCpf ? { width: '95%', display: 'inline-block' } : {}}
                                        />
                                        {(erros.cpf) &&
                                            <Form.Control.Feedback type="invalid">{erros.cpf}</Form.Control.Feedback>
                                        }

                                        <span className=" flex-grow-1">
                                            {consultandoCpf &&
                                                <FontAwesomeIcon className="ml-1" icon={faSpinner} pulse />
                                            }
                                        </span>

                                    </>
                                }

                                {dados.estrangeiro === "S" &&
                                    <>
                                        <Form.Control
                                            value={dados.numeroPassaporte || ''}
                                            maxLength='50'
                                            onChange={e => {
                                                alterarDados({ ...dados, numeroPassaporte: e.target.value })
                                            }}
                                            size="sm"
                                            isInvalid={erros.numeroPassaporte}
                                        />
                                        {erros.numeroPassaporte &&
                                            <Form.Control.Feedback type="invalid">
                                                {erros.numeroPassaporte}
                                            </Form.Control.Feedback>
                                        }
                                    </>
                                }
                            </Form.Group>

                            <Form.Group>
                                <Form.Group>
                                    <Form.Label>Nome Completo*</Form.Label>
                                    <Form.Control
                                        value={dados.nome || ''}
                                        maxLength='100'
                                        onChange={e => {
                                            alterarDados({ ...dados, nome: e.target.value })
                                        }}
                                        size="sm"
                                        isInvalid={erros.nome}
                                    />
                                    {erros.nome &&
                                        <Form.Control.Feedback type="invalid">
                                            {erros.nome}
                                        </Form.Control.Feedback>
                                    }
                                </Form.Group>
                            </Form.Group>

                            {dados.estrangeiro === "S" &&
                                <Form.Group>
                                    <Form.Label>Nacionalidade</Form.Label>
                                    <Form.Control
                                        value={dados.nacionalidade || ''}
                                        maxLength='50'
                                        onChange={e => {
                                            alterarDados({ ...dados, nacionalidade: e.target.value })
                                        }}
                                        size="sm"
                                        isInvalid={erros.nacionalidade}
                                    />
                                    {erros.nacionalidade &&
                                        <Form.Control.Feedback type="invalid">
                                            {erros.nacionalidade}
                                        </Form.Control.Feedback>
                                    }
                                </Form.Group>
                            }

                            <Form.Group>
                                <Form.Label>Nascimento*</Form.Label>
                                <InputData
                                    data={dados.dataNascimento}
                                    onChange={(data) => {
                                        alterarDados({ ...dados, dataNascimento: data });
                                    }}
                                    size="sm"
                                    isInvalid={erros.dataNascimento}
                                />
                                {erros.dataNascimento &&
                                    <Form.Control.Feedback type="invalid">
                                        {erros.dataNascimento}
                                    </Form.Control.Feedback>
                                }
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    value={dados.email || ''}
                                    maxLength='100'
                                    isInvalid={erros.email}
                                    onChange={e => {
                                        const valor = e.target.value; 
                                        alterarDados({ 
                                            ...dados, 
                                            email: valor === "" ? null : valor 
                                        });
                                    }}
                                    size="sm"
                                />
                                {erros.email &&
                                    <Form.Control.Feedback type="invalid">
                                        {erros.email}
                                    </Form.Control.Feedback>
                                }
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Telefone</Form.Label>
                                <MaskedInput
                                    className={["form-control form-control-sm", erros.telefone ? 'is-invalid' : ''].join(` `)}
                                    mask="(11) 11111-1111"                                    
                                    value={dados.telefone || ''}
                                    ref={telefoneInput}
                                    onChange={(e) => {
                                        dados.telefone = telefoneInput.current.mask.getRawValue().split('_').join('')
                                        alterarDados({ ...dados });
                                    }}
                                    onPasteCapture={async (e) => {

                                        e.preventDefault();

                                        // resgata texto que está colado
                                        let textoColado = await navigator.clipboard.readText();

                                        if(typeof textoColado !== "string") return;

                                        // remove caracteres especiais e deixa somente numeros
                                        textoColado = textoColado.replace(/\s+/g, '').replace(/\D/g, '');

                                        // monta mascara
                                        const mascara = '11111111111';
                                        
                                        // prepara valor formatado
                                        let novoValor = '';
                                        let j = 0;

                                        // percorre se baseando pela mascara
                                        for (let i = 0; i < mascara.length; i++) {

                                            // resgata valor atual
                                            const valor = textoColado[j++];
                                            
                                            // verifica se é numero e insere a variavel
                                            if(!isNaN(valor)) novoValor += valor;

                                        }

                                        // altera dados do telefone
                                        dados.telefone = novoValor.split('_').join('').replace(/\s+/g, '');
                                        alterarDados({ ...dados });
                                    }}
                                />
                                {erros.telefone &&
                                    <Form.Control.Feedback type="invalid">
                                        {erros.telefone}
                                    </Form.Control.Feedback>
                                }
                            </Form.Group>

                            <Form.Group className={'d-flex justify-content-end'}>
                                <Form.Check
                                    inline
                                    label="Informar contato emergência?"
                                    id={`checkbox-participante-contato-emergencia`}
                                    type='checkbox'                  
                                    onChange={(e) => {
                                    
                                        // resgata novo valor
                                        const mostrar = !mostrarContatoEmergencia;
    
                                        // altera valor do check box
                                        alterarMostrarContatoEmergencia(mostrar);
    
                                        // se está escondendo
                                        if(!mostrar) {
    
                                            // remove dados de emergencia
                                            alterarDados({
                                                ...dados,
                                                contatoEmergenciaTelefone: null,
                                                contatoEmergenciaNome: null
                                            });
    
                                        }
                                        
                                    }}
                                    checked={mostrarContatoEmergencia}
                                    style={{ color: 'black' }}
                                />
                            </Form.Group>

                            <Form.Group className={'d-flex justify-content-end'}>
                                <Form.Check
                                    inline
                                    label="Informar restrições e dados de saúde?"
                                    id={`checkbox-participante-dados-saude`}
                                    type='checkbox'                  
                                    onChange={(e) => {
                                    
                                        // resgata novo valor
                                        const mostrar = !mostrarDadosSaude;
    
                                        // altera valor do check box
                                        alterarMostrarDadosSaude(mostrar);
    
                                        // se está escondendo
                                        if(!mostrar) {
    
                                            // remove dados saude
                                            alterarDados({
                                                ...dados,
                                                restricoesDadosSaude: null
                                            });
    
                                        }
                                        
                                    }}
                                    checked={mostrarDadosSaude}
                                    style={{ color: 'black' }}
                                />
                            </Form.Group>

                            {mostrarContatoEmergencia ? <> 
                                <Form.Group>
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control
                                        value={dados.contatoEmergenciaNome || ''}
                                        maxLength='100'
                                        onChange={e => {
                                            alterarDados({ 
                                                ...dados, 
                                                contatoEmergenciaNome: e.target.value 
                                            })
                                        }}
                                        size="sm"
                                        isInvalid={erros?.contatoEmergenciaNome}
                                    />
                                    {erros?.contatoEmergenciaNome &&
                                        <Form.Control.Feedback type="invalid">
                                            {erros.contatoEmergenciaNome}
                                        </Form.Control.Feedback>
                                    }
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>
                                        Telefone
                                    </Form.Label>
                                    <MaskedInput
                                        className={[
                                            "form-control form-control-sm",
                                            (erros?.contatoEmergenciaTelefone) && 'is-invalid'
                                        ].join(' ')}
                                        mask="(11) 11111-1111"
                                        value={dados.contatoEmergenciaTelefone || ''}
                                        ref={telefoneEmergenciaInput}
                                        onChange={(e) => {
                                            dados.contatoEmergenciaTelefone = telefoneEmergenciaInput.current.mask.getRawValue().split('_').join('')
                                            alterarDados({ ...dados });
                                        }}
                                        onPasteCapture={async (e) => {

                                            e.preventDefault();
    
                                            // resgata texto que está colado
                                            let textoColado = await navigator.clipboard.readText();
    
                                            if(typeof textoColado !== "string") return;
    
                                            // remove caracteres especiais e deixa somente numeros
                                            textoColado = textoColado.replace(/\s+/g, '').replace(/\D/g, '');
    
                                            // monta mascara
                                            const mascara = '11111111111';
                                            
                                            // prepara valor formatado
                                            let novoValor = '';
                                            let j = 0;
    
                                            // percorre se baseando pela mascara
                                            for (let i = 0; i < mascara.length; i++) {
    
                                                // resgata valor atual
                                                const valor = textoColado[j++];
                                                
                                                // verifica se é numero e insere a variavel
                                                if(!isNaN(valor)) novoValor += valor;
                                                
                                            }
    
                                            // altera dados do telefone
                                            dados.contatoEmergenciaTelefone = novoValor.split('_').join('').replace(/\s+/g, '');
                                            alterarDados({ ...dados });
                                        }}
                                    />
                                    {(erros?.contatoEmergenciaTelefone) &&
                                        <Form.Control.Feedback type="invalid">{erros.contatoEmergenciaTelefone}</Form.Control.Feedback>
                                    }
                                </Form.Group>
                            </> : <></>}

                            {mostrarDadosSaude ? 
                                <Form.Group>
                                    <Form.Label>Restrições e dados de saúde</Form.Label>
                                    <Form.Control
                                        value={dados.restricoesDadosSaude || ''}
                                        onChange={e => {
                                            alterarDados({ 
                                                ...dados, 
                                                restricoesDadosSaude: e.target.value 
                                            })
                                        }}
                                        size="sm"
                                        as="textarea"
                                        rows={3}
                                        isInvalid={erros?.restricoesDadosSaude}
                                    />
                                {erros?.restricoesDadosSaude &&
                                    <Form.Control.Feedback type="invalid">
                                        {erros.restricoesDadosSaude}
                                    </Form.Control.Feedback>
                                }
                                </Form.Group> : 
                                <></>
                            }

                            <p
                                style={{
                                    fontWeight: 'bold',
                                    fontStyle: 'italic',
                                    fontSize: 11
                                }}
                            >
                                Autorizo o uso dos meus dados pessoais para emissão do Seguro Aventura, e declaro
                                que estou ciente e fui informado pelo promotor do evento dos riscos envolvidos na
                                prática das atividades de turismo e esportes de aventura.
                            </p>

                            <div className="text-right">
                                <Button
                                    variant="primary"
                                    onClick={salvar}
                                    disabled={salvando}
                                >
                                    <span>Salvar</span>
                                    {salvando &&
                                        <FontAwesomeIcon className="ml-1" icon={faSpinner} pulse />
                                    }
                                </Button>
                            </div>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    </div>


}