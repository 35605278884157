import React from 'react';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form } from 'react-bootstrap';
import { DebounceInput } from 'react-debounce-input';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import BotaoFiltroEvento from './BotaoFiltroEvento';

// cria os parametros
function Parametros({
    parametros, 
    gerandoApolice,
    alterarParametros,
    gerarApoliceCliente,
    permissaoCriarEvento,
    possuiEventoGerarBoleto,
    alterarMostrarModalBoletos
}){
    
    const history = useHistory();
    const dispatch = useDispatch();

    // parametros
    return <div className="parametros">
        <div className="parametros-inicial">
            <Button 
                variant="danger"
                onClick={() => {
                    
                    // limpa parametros na url
                    history.replace({
                        search: ''
                    });

                    // logout
                    dispatch({
                        type: 'cartaOferta/ATUALIZAR',
                        dados: null
                    });

                }}
            >
                <FontAwesomeIcon className="icone" icon={["fas", "power-off"]} />
            </Button>
            {permissaoCriarEvento && <Link to='/formularios/propostaSeguro/evento'>
                <Button variant="padrao-azul" className="botao-cadastrar">
                    <FontAwesomeIcon className="icone" icon={["fas", "plus"]} />
                    <span>
                        Novo evento
                    </span>
                </Button>
            </Link>}
            <DebounceInput 
                placeholder="Pesquisa" 
                debounceTimeout={400}
                element={Form.Control} 
                value={parametros.texto}
                onChange={(e) =>{
                    alterarParametros({...parametros, texto: e.target.value, pagina: 1})
                }}
            />
            <Button 
                variant="padrao-azul" 
                onClick={gerarApoliceCliente}
                className="botao-cadastrar"  
            >
                {gerandoApolice ?
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    : <FontAwesomeIcon className="icone" icon={["fas", "file-pdf"]} />
                }                            
                <span>
                    Minha Apólice
                </span>
            </Button>
            {possuiEventoGerarBoleto &&
                <Button 
                    variant={"padrao-azul"} 
                    className={"botao-cadastrar"}
                    onClick={() => alterarMostrarModalBoletos(true)}
                >
                    <FontAwesomeIcon 
                        className="icone" 
                        icon={["fas", "barcode"]} 
                        style={{
                            marginTop: 3
                        }}
                    />
                    <span>
                        Gerar Boleto de Eventos
                    </span>
                </Button>
            }
        </div>
        <div className='parametros-filtros-eventos'>
            <BotaoFiltroEvento 
                onClick={() => {
                    if(parametros.estados.includes('aberto')) {
                        return alterarParametros({
                            ...parametros,
                            estados: parametros.estados.filter(e => e !== 'aberto')
                        });
                    }
                    alterarParametros({
                        ...parametros,
                        estados: [...parametros.estados, 'aberto']
                    });
                }}
                texto={'Abertos'}
                cor={'#ffc107'}
                icone={'smile'}
                selecionado={parametros.estados.includes('aberto')}
            />
            <BotaoFiltroEvento 
                onClick={() => {
                    if(parametros.estados.includes('andamento')) {
                        return alterarParametros({
                            ...parametros,
                            estados: parametros.estados.filter(e => e !== 'andamento')
                        });
                    }
                    alterarParametros({
                        ...parametros,
                        estados: [...parametros.estados, 'andamento']
                    });
                }}
                texto={'Andamento'}
                cor={'#28a745'}
                icone={'smile-wink'}
                selecionado={parametros.estados.includes('andamento')}
            />
            <BotaoFiltroEvento 
                onClick={() => {
                    if(parametros.estados.includes('finalizado')) {
                        return alterarParametros({
                            ...parametros,
                            estados: parametros.estados.filter(e => e !== 'finalizado')
                        });
                    }
                    alterarParametros({
                        ...parametros,
                        estados: [...parametros.estados, 'finalizado']
                    });
                }}
                texto={'Finalizados'}
                cor={'#007bff'}
                icone={'laugh-beam'}
                selecionado={parametros.estados.includes('finalizado')}
            />
            <BotaoFiltroEvento 
                onClick={() => {
                    if(parametros.estados.includes('cancelado')) {
                        return alterarParametros({
                            ...parametros,
                            estados: parametros.estados.filter(e => e !== 'cancelado')
                        });
                    }
                    alterarParametros({
                        ...parametros,
                        estados: [...parametros.estados, 'cancelado']
                    });
                }}
                texto={'Cancelados'}
                cor={'#dc3545'}
                icone={'frown'}
                selecionado={parametros.estados.includes('cancelado')}
            />
        </div>
    </div>

}

export default Parametros;