// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = Object.assign({
    dados: null
}, JSON.parse(window.localStorage.getItem('cartaOferta'))), action) {

    // ações globais
    switch(action.type){
        case 'cartaOferta/ATUALIZAR':
            let dadosCartaOferta = {
                dados: action.dados
            };
            window.localStorage.setItem('cartaOferta', JSON.stringify(dadosCartaOferta));
            return Object.assign({}, state, dadosCartaOferta);
        default: 
            return state
    }
}